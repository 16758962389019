<template>
  <el-dialog width="600" title="eMSP合作關係設定" :visible="dialogVisible" @close="dialogDataOnClose" @open="dialogDataOnOpen()">
    <div style="text-align: center">
      <el-transfer
          style="text-align: left; display: inline-block"
          :data=data
          v-model="value"
          filterable
          :titles="['尚未合作營運商', '已合作營運商']"
          :button-texts="['移除合作', '加入合作']"
          :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"
          @change="handleChange">
        <span slot-scope="{ option }">{{ option.label }}</span>
<!--        <el-button class="transfer-footer" slot="left-footer" size="small">操作</el-button>-->
<!--        <el-button class="transfer-footer" slot="right-footer" size="small">操作</el-button>-->
      </el-transfer>
    </div>
    <br/><br/>
    <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"  @click="updateData()">{{$t('Save')}}</el-button>

    <br/><br/>
  </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import _ from 'lodash';
export default {
  name: "CooperationSettingEMSP",
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    serviceData: {
      require: false,
      default: undefined,
      type: Object
    },
    dialogVisible: {
      require: true,
      default: undefined,
      type: Boolean
    },
  },
  data() {
    return {
      data: [],   //el-transfer的資料輸入,array[object]
      value: [],  //el-transfer的被選取資料的index, array[int]
      service: {  //接收prop serviceData值
        id:undefined,
        name:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        ocpiPartyId: undefined,
        ocpiCountryCode: undefined,
        ocpiVersionId: undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
        exclude_partnerCPOs:[],
        exclude_partnerEMSPs:[],
      },
      allEMSPs:[] //service的所有EMSPs,由partnerEMSPs+exclude_partnerEMSPs合成
    };
  },
  methods: {

    dialogDataOnOpen(){
      let vm = this;
      this.service = this.serviceData;
      console.log("dialogDataOnOpen", this.service);
      //合併成所有營運商集合
      this.allEMSPs = _.union(this.service.exclude_partnerEMSPs, this.service.partnerEMSPs);
      console.log('this.service.allEMSPs',this.allEMSPs);
      //轉換資料格式以符合el-transfer元件資料規格
      this.allEMSPs.forEach((element,index) => {
        vm.data.push({
              key: index,
              label: `${ element.name }-(ID:${ element.id})`,
              code: element.code,
            }
        )
      });
      //找出已合作的營運商 index array
      vm.allEMSPs.forEach((Aelement,Aindex) => {
        this.service.partnerEMSPs.forEach((Belement,Bindex) => {

          if(Aelement.code == Belement.code){
            console.log("Aelement.code="+Aelement.code+",Belement.code="+Belement.code+",Aindex="+Aindex+",Bindex="+Bindex);
            vm.value.push(Aindex);
          }

        });
      });
    },

    handleChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
    },

    dialogDataOnClose() {
      this.resetUpdateQuery();
      //this.$refs.dataForm.resetFields() // 表單校驗結果重置
      //this.$refs.ocpiDataForm.resetFields() //ocpi表單校驗結果重置
      this.$emit('close') // 觸發父層 close
    },

    resetUpdateQuery() {
      this.service = {  //接收prop serviceData值
        id:undefined,
        name:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        ocpiPartyId: undefined,
        ocpiCountryCode: undefined,
        ocpiVersionId: undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
        exclude_partnerCPOs:[],
        exclude_partnerEMSPs:[],
      },
      this.value = [],
      this.data = [],
      this.allEMSPs = []
    },

    updateData() {
      let vm = this;
      this.service.partnerEMSPs=[];
      this.service.exclude_partnerEMSPs=[];

      this.value.forEach((item) => {

        this.service.partnerEMSPs.push(this.allEMSPs[item]);

      });

      console.log("this.service.partnerEMSPs",this.service.partnerEMSPs);

      this.allEMSPs.forEach((Aelement,Aindex) => {
        if(-1 == _.findIndex(vm.value, function(o) { return o == Aindex; })){
          //console.log("exclude Partner",Aelement.name);
          this.service.exclude_partnerEMSPs.push(Aelement);
        }
      });

      console.log("this.service.exclude_partnerEMSPs",this.service.exclude_partnerEMSPs);

      this.axios.put('/api/v1/ocpi/service', this.service).then(() => {
        this.$message({
          showClose: true,
          message: this.$t('SuccessEdited'),
          type: 'success'
        })
        this.dialogDataOnClose()
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('FailEdited') + ' ('+err.data+')' ,
          type: 'error'
        })
      })
    },
  },
}
</script>

<style scoped>
.transfer-footer {
  margin-left: 20px;
  padding: 6px 5px;
}
/deep/ .el-transfer-panel{
  width: 300px;
  height: 450px;
}

/deep/ .el-transfer-panel__list.is-filterable {
  height: 300px;
}
</style>
